import React from "react"

function Footer(){
    return(
        <footer style={{backgroundColor:'#fff',color:'#337c75'}}> 
               <div className="footer__bottom">
                  <div className="container">
                     <div className="row">
                        <div className="col-xxl-12">
                           <div className="footer__copyright text-center">
                           <a href="https://hnhtechsolutions.com/">   <p style={{color:'#fff'}}>© 2023 Oxygen 8. Design By HNH Tech Solution </p></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>   
         </footer>
    )
}
export default Footer